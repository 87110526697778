.ConfirmModal-Button-Container {
  bottom: 10px;
  display: flex;
  justify-content: space-around;
  padding: 10px;
  position: absolute;
  width: 50%;
}

.ConfirmModal-Container {
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.ConfirmModal-Message-Immatriculation {
  font-size: larger;
  font-weight: bold;
}

.ConfirmModal-Title-Container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  height: 50px;
  font-size: 20px;
  border-bottom: 1px solid #35A7D7;
}
