h1 {
  margin: 40px 0 20px 0;
  width: 100%;
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80%;
}

.containerError {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  text-align: center;
}

.form {
  margin-top: 15px;
  margin-bottom: auto;
}

.textContainer {
  width: 80%;
  height: 80%;
  text-align: center;
}

.textError {
  color: red;
  font-size: 12px;
}

.textTitle {
  font-weight: 600;
}

#myPaymentForm button {
  background-color: black;
  align-items: center;
  justify-content: center;
  display: flex;
  color: #35A7D7;
}
